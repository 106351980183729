import React from 'react'

import { usePathname } from 'next/navigation'

import { HeaderItems } from '~/utils/getHeaderItems'

import { Button } from '~/components/Button/Button'

// import { gt } from '~/lib/gt'
import styles from './AuthButtons.module.css'

export function AuthButtons({ buttonsTranslations }: { buttonsTranslations: HeaderItems['buttons'] }) {
  const pathname = usePathname()
  return (
    <div className={styles.authButtonsContainer}>
      <Button href={`/login?redirectTo=${pathname}`} variant='ghost' className={styles.signButton} withArrow={false} size='small'>
        {buttonsTranslations.signIn}
        {/* {gt.tp('Header', 'Sign in')} */}
      </Button>
      <Button href={`/register?redirectTo=${pathname}`} className={styles.signButton} withArrow={false} size='small'>
        {buttonsTranslations.signUp}
        {/* {gt.tp('Header', 'Sign up')} */}
      </Button>
    </div>
  )
}
