import { HeaderProps } from '~/components/Header/Header'

import { HeaderMenuItem } from '../../HeaderMenuItem'

interface RightLinksProps extends Pick<HeaderProps, 'rightLinks'> {
  mergedTheme: { [x: string]: string }
}

export function RightLinks({ rightLinks, mergedTheme }: RightLinksProps) {
  return rightLinks?.map(({ label, href, icon, iconAlt }) => (
    <HeaderMenuItem key={label} label={label} href={href} theme={mergedTheme} icon={icon} iconAlt={iconAlt} />
  ))
}
