import { useId } from 'react'

import * as menu from '@zag-js/menu'
import { normalizeProps, useMachine } from '@zag-js/react'

export function useMenuMachine(menuContext: Omit<menu.Context, 'id'>) {
  const [state, send] = useMachine(
    menu.machine({
      ...menuContext,
      id: useId(),
    })
  )

  return menu.connect(state, send, normalizeProps)
}
