import { useApolloClient } from '@apollo/client'

import { HeaderItems } from '~/utils/getHeaderItems'

import { UserDataQuery, useSignOutMutation } from '~/app/generated/graphql'

import { UserMenuHeader } from './components/UserMenuHeader'
import { UserMenuList } from './components/UserMenuList'
import styles from './UserMenu.module.css'

type UserMenuProps = {
  user: NonNullable<UserDataQuery['user']>
  LogoutBtn: React.ElementType<React.ComponentPropsWithoutRef<'button'> & { label: string }>
  headerTranslation: HeaderItems
}

const Separator = () => <div className={styles.separator} />

export function UserMenu({ user, LogoutBtn, headerTranslation }: UserMenuProps) {
  const client = useApolloClient()
  const [signOut] = useSignOutMutation({
    onCompleted: () => {
      client.resetStore()
    },
  })

  return (
    <div className={styles.userMenuContainer}>
      <UserMenuHeader user={user} />

      <Separator />

      <UserMenuList userMenuRoutes={headerTranslation.userMenuRoutes} />

      <Separator />

      <LogoutBtn onClick={signOut} label={headerTranslation.buttons.logout} />
    </div>
  )
}
