import { useCallback, useLayoutEffect, useState } from 'react'

import { useCollapse } from '~/utils/hooks'

import { UserDataQuery } from '~/app/generated/graphql'
import { Button } from '~/components/Button/Button'
import { LoginSectionMenu } from '~/components/Header/components/RightSection/components/LoginSectionMenu'
import { HeaderProps } from '~/components/Header/Header'

import { LanguageSwitcherButton, LanguageSwitcherList, useLanguageSwitcher } from '../../../LanguageSwitcher/LanguageSwitcher.utils'
import { MiddleLinks } from '../../../MiddleLinks'
import { AuthButtons } from '../AuthButtons'
import { RightLinks } from '../RightLinks'

import { MenuHeader, ToogleButton } from './MobileMenu.components'
import styles from './MobileMenu.module.css'

export interface MobileMenuProps
  extends Pick<
    HeaderProps,
    'rightLinks' | 'middleLinks' | 'additionalMobileMenuLinks' | 'headerTranslation' | 'buttonText' | 'redirectUrl'
  > {
  mergedTheme: { [x: string]: string }
  user?: UserDataQuery['user']
}

export function MobileMenu({
  middleLinks,
  additionalMobileMenuLinks,
  mergedTheme,
  user,
  rightLinks,
  headerTranslation,
  buttonText,
  redirectUrl,
}: Readonly<MobileMenuProps>) {
  const api = useCollapse()

  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const toggleMenu = useCallback(() => {
    setIsMenuOpen(isMenuOpen => !isMenuOpen)
  }, [])

  useLayoutEffect(() => {
    if (isMenuOpen) document.body.style.overflow = 'hidden'
    else document.body.style.overflow = 'auto'

    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [isMenuOpen])

  const { options, currentLocation, onLanguageChange } = useLanguageSwitcher()

  const combinedLinks = [...(middleLinks ?? []), ...(additionalMobileMenuLinks ?? [])]
  return (
    <>
      <div className={styles.rightMobile}>
        {user && <LoginSectionMenu {...{ user, rightLinks, mergedTheme, middleLinks, headerTranslation }} />}
        <ToogleButton onClick={toggleMenu} />
      </div>
      {isMenuOpen && (
        <div className={styles.overlayContainer}>
          <div className={styles.overlay}>
            <MenuHeader onClick={toggleMenu} />

            <MiddleLinks onClick={() => setIsMenuOpen(false)} mergedTheme={mergedTheme} middleLinks={combinedLinks} isMobile />

            <RightLinks mergedTheme={mergedTheme} rightLinks={rightLinks} />

            <div {...api.getRootProps()}>
              <div className={styles.collapseSection}>
                <LanguageSwitcherButton getTriggerProps={api.getTriggerProps} location={currentLocation} open={api.open} />
              </div>
              <LanguageSwitcherList options={options} onClick={onLanguageChange} getContentProps={api.getContentProps} />
            </div>
            {buttonText && redirectUrl && (
              <Button href={redirectUrl} className={mergedTheme.button}>
                {buttonText}
              </Button>
            )}
            {!user && <AuthButtons buttonsTranslations={headerTranslation.buttons} />}
          </div>
        </div>
      )}
    </>
  )
}
