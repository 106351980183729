/* eslint-disable prettier/prettier */
/* Autogenerated GT module to not modify */

const locales = {
    uk: () =>
                                import(
                                    /* webpackPreload: true */
                                    './uk.json'
                                ),
pl: () =>
                                import(
                                    /* webpackPreload: true */
                                    './pl.json'
                                ),
en: () =>
                                import(
                                    /* webpackPreload: true */
                                    './en.json'
                                ),
de: () =>
                                import(
                                    /* webpackPreload: true */
                                    './de.json'
                                ),
cs: () =>
                                import(
                                    /* webpackPreload: true */
                                    './cs.json'
                                )
} as any

export default locales
