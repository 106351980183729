import { FestivalEditionRestaurant, ReservableExtra, Restaurant } from '../CollectionSection'

export const getReservableExtraProperties = (reservableExtra: ReservableExtra) => {
  const { restaurant, image, id, name, slug, price } = reservableExtra
  return {
    eyebrowText: restaurant?.name,
    titleText: name,
    photo: image?.preview,
    name,
    slug,
    price,
    id,
  }
}

export const getRestaurantProperties = (FER: Restaurant) => {
  return {
    eyebrowText: FER?.description,
    titleText: FER?.name,
    photo: FER?.imageFiles[0]?.preview || '/lp/assets/generic/hero_background.png',
    name: FER?.name,
    slug: FER?.slug,
    price: null,
    id: FER?.id,
  }
}

export const getFERProperties = (FER: FestivalEditionRestaurant) => {
  return {
    eyebrowText: FER?.restaurant.description,
    titleText: FER?.restaurant.name,
    photo: FER?.imageFiles[0]?.preview || '/lp/assets/generic/hero_background.png',
    name: FER?.restaurant.name,
    slug: FER?.restaurant.slug,
    price: null,
    id: FER?.id,
  }
}
