// @ts-strict-ignore
import dayjs from 'dayjs'

import { COOKIE, setCookie } from '~/utils/cookies'
import { getLogger } from '~/utils/logger'

import localeModules from '~/locale/dist'

import { gt } from '.'

const logDebug = getLogger('locale:gt', 'debug')
const logWarn = getLogger('locale:gt', 'warn')

type Locale = undefined | keyof typeof localeModules

export async function loadLocales(locale: Locale, localeMessages?: Record<string, string>) {
  const languagePack = localeModules[locale!]
  const stringLocale = String(locale)

  if (localeMessages) {
    gt.update(stringLocale, localeMessages)
    logDebug(`Locale ${stringLocale} has been preloaded`)
    return locale
  }

  if (!languagePack) {
    logWarn(`Module ${stringLocale} not found`)
    throw new Error('Unsupported locale')
  }

  logDebug(`Start to load ${stringLocale} locale`)
  const { default: messages } = await languagePack()

  logDebug(`Locale ${stringLocale} has been loaded`)
  gt.update(stringLocale, messages)

  if (typeof window !== 'undefined') {
    setCookie(COOKIE.NEXT_LOCALE, stringLocale, { expires: dayjs().add(10, 'years').toDate(), path: '/' })
  }

  return locale
}
