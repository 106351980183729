// @ts-ignore
import * as cookie from 'cookie'

export const COOKIE = {
  LOCATION: 'location',
  NEXT_LOCALE: 'NEXT_LOCALE',
  ADULT: 'adult',
  OPTANON_CONSENT: 'OptanonConsent',
  ALERT_BOX_CLOSED: 'OptanonAlertBoxClosed',
}

export const setCookie = (key: string, value: string | number | bigint | boolean | symbol, options: cookie.SerializeOptions = {}) => {
  const _opts = {
    path: '/',
    ...options,
  }
  document.cookie = cookie.serialize(key, value.toString(), _opts)
}

export const getCookie = (key: string) => {
  let cookieString
  if (typeof document !== 'undefined') {
    cookieString = document.cookie
  }
  return cookieString && cookie.parse(cookieString)[key] ? cookie.parse(cookieString)[key] : null
}

export const getCookieAsParsedJson = (key: string) => {
  const cookie = getCookie(key)
  return cookie ? JSON.parse(cookie) : null
}

export const setCookiesAsJson = (key: string, value: any, res: any, options: cookie.SerializeOptions = {}) => {
  const defaultOptions: cookie.SerializeOptions = {
    sameSite: 'lax',
    secure: true,
  }

  const finalOptions = { ...defaultOptions, ...options }

  setCookie(key, JSON.stringify(value), finalOptions)
}

export const deleteCookie = (key: string, options = {}) => {
  const expiredOptions = { path: '/', expires: new Date(), ...options }

  document.cookie = cookie.serialize(key, '', expiredOptions)
}
