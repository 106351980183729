import React from 'react'

import { HeaderLink } from '../Header'

import { HeaderMenuItem } from './HeaderMenuItem'

type MiddleLinksProps = {
  middleLinks?: HeaderLink[]
  mergedTheme: { [x: string]: string }
  isMobile?: boolean
  onClick?: () => void
}

export function MiddleLinks({ middleLinks, mergedTheme, isMobile = false, onClick }: MiddleLinksProps) {
  return (
    <div onClick={onClick} className={isMobile ? mergedTheme.mobileMiddle : mergedTheme.middle}>
      {middleLinks?.map(({ label, href, icon, iconAlt }) => (
        <HeaderMenuItem
          key={label}
          label={icon ? iconAlt : label}
          href={href}
          theme={mergedTheme}
          className={isMobile ? undefined : 'middleItemAnimation'}
          onClick={onClick}
        />
      ))}
    </div>
  )
}
