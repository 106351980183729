/* eslint-disable @next/next/no-img-element */
'use client'
import React from 'react'

import { HeaderItems } from '~/utils/getHeaderItems'

import { HeaderLogo, HeaderLogoProps, MiddleLinks, RightSection, TopBarLinks, TopBarLinksProps } from './components'
import styles from './Header.module.css'

export type HeaderLink = {
  href: string
  label?: string
  icon?: string
  iconAlt?: string
  iconEnd?: React.ReactElement
}

export type HeaderProps = {
  middleLinks?: HeaderLink[]
  additionalMobileMenuLinks?: HeaderLink[]
  rightLinks?: HeaderLink[]
  topBarLinks?: TopBarLinksProps
  buttonText?: string
  redirectUrl?: string
  logo?: HeaderLogoProps
  theme?: typeof styles
  headerTranslation: HeaderItems
}

export const Header = ({
  middleLinks,
  additionalMobileMenuLinks,
  topBarLinks,
  buttonText,
  redirectUrl,
  logo = { src: '/lp/assets/logo/logo-festival-rw.svg', alt: 'RW' },
  theme = styles,
  rightLinks,
  headerTranslation,
}: HeaderProps) => {
  const mergedTheme = { ...styles, ...theme }
  // TODO refactoring of mergedTheme to move the styles to the specific components

  return (
    <header className={`${mergedTheme.headerContainer}`}>
      <div className='container'>
        <TopBarLinks mergedTheme={mergedTheme} topBarLinks={topBarLinks} />
        <nav className={mergedTheme.mainBarContainer}>
          <HeaderLogo mergedTheme={mergedTheme} logo={logo} />
          <MiddleLinks mergedTheme={mergedTheme} middleLinks={middleLinks} />

          <RightSection
            {...{ middleLinks, additionalMobileMenuLinks, rightLinks, logo, mergedTheme, headerTranslation, buttonText, redirectUrl }}
          />
        </nav>
      </div>
    </header>
  )
}
