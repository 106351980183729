import Link from '~/components/Link'

import { HeaderLink } from '../Header'

import { HeaderMenuItemProps } from './HeaderMenuItem'
import { LanguageSwitcher } from './LanguageSwitcher'

export type TopBarLinksProps = {
  left?: HeaderLink[]
  right?: HeaderLink[]
}

const TopBarMenuItem = ({ label, href, theme, iconEnd }: HeaderMenuItemProps) => {
  return (
    <Link className={theme.topBarItem} href={href}>
      <span>{label}</span>
      {iconEnd}
    </Link>
  )
}

const renderItems = (mergedTheme: { [x: string]: string }, items?: HeaderLink[]) =>
  items?.map(({ label, href, iconEnd }) => <TopBarMenuItem key={label} label={label} href={href} theme={mergedTheme} iconEnd={iconEnd} />)

export const TopBarLinks = ({ topBarLinks, mergedTheme }: { topBarLinks?: TopBarLinksProps; mergedTheme: { [x: string]: string } }) => {
  return (
    <nav className={`${mergedTheme.topBarContainer}`}>
      <div className={mergedTheme.topBarLeft}>{topBarLinks !== undefined && renderItems(mergedTheme, topBarLinks?.left)}</div>
      <div className={mergedTheme.topBarRight}>
        {topBarLinks !== undefined && renderItems(mergedTheme, topBarLinks?.right)}
        <LanguageSwitcher />
      </div>
    </nav>
  )
}
