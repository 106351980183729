import { FC } from 'react'

// eslint-disable-next-line no-restricted-imports
import NextLink, { LinkProps } from 'next/link'

const Link: FC<LinkProps & { children?: React.ReactNode; className?: string; rel?: string } & React.RefAttributes<HTMLAnchorElement>> = ({
  children,
  href,
  prefetch,
  ...rest
}) => {
  if (href.toString().match(/^\//)) {
    href = `..${href}`
  }

  return (
    <NextLink {...rest} href={href} prefetch={prefetch ?? false}>
      {children}
    </NextLink>
  )
}

export default Link
