import React from 'react'

// import { gt } from '~/lib/gt'
import styles from './UserMenuComponents.module.css'
import { MenuItem } from './UserMenuList'

export function LogoutBtn(props: React.ComponentPropsWithoutRef<'button'> & { label: string }) {
  return (
    <button className={styles.menuButtonContainer} {...props}>
      {/* <MenuItem label={gt.tp('UserMenuItem', 'Logout')} icon='logout' /> */}
      <MenuItem label={props.label} icon='logout' />
    </button>
  )
}
