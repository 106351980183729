import styles from '../../Header.module.css'

export type HeaderMenuItemProps = {
  label?: string
  href: string
  theme: typeof styles
  className?: string
  icon?: string
  iconAlt?: string
  iconEnd?: React.ReactElement
  onClick?: () => void
}

export const HeaderMenuItem = ({ label, href, theme, className, icon, iconAlt, onClick }: HeaderMenuItemProps) => {
  const mergedClassName = className ? theme[className] : theme.middleItem
  return (
    <a onClick={onClick} key={label} href={href}>
      {icon ? (
        <img className={theme.icon} src={icon} width={22} height={22} alt={iconAlt} draggable={false} />
      ) : (
        <span className={mergedClassName}>{label}</span>
      )}
    </a>
  )
}
