'use client'
import React from 'react'

import { formatPrice } from '~/utils/helpers'
import imgProxyLoader from '~/utils/imgproxy'

import { CollectionCategoryEnum, FestivalEditionRestaurant, ReservableExtra, Restaurant } from '~/app/generated/graphql'

import { useLocation } from '../LocationMenu/LocationMenuUtils'

import styles from './ReservationOfferCard.module.css'
import { ReservationOfferCardProps } from './ReservationOfferCardTypes'
import { getFERProperties, getReservableExtraProperties, getRestaurantProperties } from './ReservationOfferCardUtils'

export const ReservationOfferCard = ({
  item,
  category,
  type = 'regular',
  theme = styles,
  guestLabel = 'Guest',
  listingRedirect,
  feCode,
}: ReservationOfferCardProps) => {
  const mergedTheme = { ...styles, ...theme }

  const properties = (() => {
    switch (category) {
      case CollectionCategoryEnum.Reservableextra:
        return getReservableExtraProperties(item as ReservableExtra)
      case CollectionCategoryEnum.Festivaleditionrestaurant:
        return getFERProperties(item as FestivalEditionRestaurant)
      case CollectionCategoryEnum.Restaurant:
        return getRestaurantProperties(item as Restaurant)
    }
  })()

  const { eyebrowText, titleText, photo, slug, price, id } = properties
  const { selectedRegion } = useLocation()
  const href = listingRedirect
    ? `/restaurants/festival/${listingRedirect}?ferId=${id}&location=${selectedRegion?.id}-${selectedRegion?.name}`
    : `/${slug}`

  return (
    <a className={mergedTheme.cardActions} href={href} target='_blank'>
      <div className={mergedTheme.styledTile}>
        <div
          className={`${mergedTheme.photoWrapper} ${type === 'regular' ? mergedTheme.photoWrapperDesktop : mergedTheme.photoWrapperMobile}`}>
          <div className={mergedTheme.photo}>
            <img
              src={imgProxyLoader({ src: photo || '/lp/assets/generic/hero_background.png', width: 601 })}
              alt={titleText}
              className={mergedTheme.image}
              height={type === 'regular' ? 305 : 183}
              width={305}
            />
          </div>
        </div>
        <div className={mergedTheme.infoBox}>
          <div className={mergedTheme.infoRow}>
            <h3 className={mergedTheme.infoTitle}>{titleText}</h3>
            <div className={mergedTheme.eyebrow}>{eyebrowText}</div>
          </div>
          {price && (
            <div className={mergedTheme.detailsContainer}>
              <h6 className={mergedTheme.heading6}>{formatPrice(price)}&nbsp;/&nbsp;</h6>
              <h6 className={mergedTheme.heading6Regular}>{guestLabel}</h6>
            </div>
          )}
        </div>
      </div>
    </a>
  )
}
