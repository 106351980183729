'use client'
import { useId } from 'react'

import { mergeProps, normalizeProps, Portal, useMachine } from '@zag-js/react'
import * as select from '@zag-js/select'

import styles from './Select.module.css'

interface Item {
  label: string
  value: string // zag.js value must be a string can't be a object!
}

interface SelectProps {
  items: Array<Item>
  label?: string
  value?: string
  onChange?: (details: { value: string[] }) => void
}

const Select = ({ items, label, value, onChange }: SelectProps) => {
  const collection = select.collection({
    items,
  })

  const [state, send] = useMachine(
    select.machine({
      id: useId(),
      collection,
      onValueChange: details => {
        onChange?.(details)
      },
    }),
    {
      context: {
        value: [value?.toString() ?? ''],
        collection,
        onValueChange: details => {
          onChange?.(details)
        },
      },
    }
  )

  const api = select.connect(state, send, normalizeProps)

  return (
    <div {...api.getRootProps()} className={styles.container}>
      <div {...api.getControlProps()} className={styles.control}>
        {label && (
          <label {...api.getLabelProps()} className={styles.label}>
            {label}
          </label>
        )}
        <button className={styles.value} {...api.getTriggerProps()}>
          {api.valueAsString || 'Select option'}
          <div className={styles.icon}>
            <img draggable={false} src={'/lp/assets/icons/arrow.svg'} alt={'V'} />
          </div>
        </button>
      </div>

      <Portal>
        <div {...mergeProps(api.getPositionerProps(), { style: { zIndex: 1 } })}>
          <ul {...api.getContentProps()} className={styles.content}>
            {items.map(item => (
              <li key={item.value} {...api.getItemProps({ item })} className={styles.item}>
                <span>{item.label}</span>
              </li>
            ))}
          </ul>
        </div>
      </Portal>
    </div>
  )
}

export default Select
