import { AnchorHTMLAttributes, ButtonHTMLAttributes, PropsWithChildren } from 'react'

import Image from 'next/image'

import sprite from '@restaurantclub/svg-client-icons'

import Link from '~/components/Link'

import styles from './Button.module.css'

type BaseProps = {
  size?: 'small' | 'large'
  variant?: 'primary' | 'ghost' | 'outlined'
  className?: string
  withArrow?: boolean
  disabled?: boolean
}

type ButtonBaseProps = BaseProps &
  ButtonHTMLAttributes<HTMLButtonElement> & {
    href?: never
  }

type LinkProps = BaseProps &
  Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'href'> & {
    href: string
  }

export type ButtonProps = ButtonBaseProps | LinkProps

const Chevron = ({ className }: { className: string | undefined }) => (
  <div style={{ width: '24px', height: '24px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <Image src={`${sprite}#chevron-right`} className={className} alt={`chevron-right`} width={12} height={12} />
  </div>
)

export const Button = ({
  href,
  size = 'large',
  variant = 'primary',
  children,
  className,
  withArrow = true,
  disabled,
  ...rest
}: PropsWithChildren<ButtonProps>) => {
  const combinedClassName = `${styles.button} ${styles[size] ?? ''} ${styles[variant] ?? ''} ${className ?? ''}`
  const iconRight = withArrow ? <Chevron className={styles.iconRight} /> : null

  if (href) {
    const linkProps = rest as LinkProps
    return (
      <Link className={combinedClassName} {...linkProps} href={href}>
        {children}
        {iconRight}
      </Link>
    )
  } else {
    const buttonProps = rest as ButtonBaseProps
    return (
      <button className={combinedClassName} disabled={disabled} type={buttonProps.type} {...buttonProps}>
        {children}
        {iconRight}
      </button>
    )
  }
}
