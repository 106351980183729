import Image from 'next/image'

import sprite from '@restaurantclub/svg-client-icons/dist/sprite.svg'

import { HeaderItems } from '~/utils/getHeaderItems'

// import { gt } from '~/lib/gt'
import styles from './UserMenuComponents.module.css'
// import { getUserMenuRoutes } from './UserMenuList.constant'

export function MenuItem({ label, icon }: Readonly<{ label: string; icon: string }>) {
  return (
    <>
      <Image data-icon={icon} src={`${sprite}#${icon}`} alt={`Icon ${icon}`} className={styles.menuButtonIcon} height={24} width={24} />
      <h6 className={styles.menuButtonLabel}>{label}</h6>
    </>
  )
}

export function UserMenuList({ userMenuRoutes }: { userMenuRoutes: HeaderItems['userMenuRoutes'] }) {
  return (
    <div className={styles.buttonsContainer}>
      {userMenuRoutes.map(routeObj => (
        <a key={routeObj.href} href={routeObj.href} className={styles.menuButtonContainer}>
          {/* <MenuItem label={gt.tp('UserMenuItem', routeObj.label)} icon={routeObj.icon} /> */}
          <MenuItem label={routeObj.label} icon={routeObj.icon} />
        </a>
      ))}
    </div>
  )
}
