import React from 'react'

import Link from '~/components/Link'

export type HeaderLogoProps = {
  src: string
  alt: string
  href?: string
}

export function HeaderLogo({ logo, mergedTheme }: { logo: HeaderLogoProps; mergedTheme: { [x: string]: string } }) {
  return (
    <div className={mergedTheme.left}>
      {logo.href ? (
        <Link href={logo.href}>
          <img className={mergedTheme.logo} src={logo.src} alt={logo.alt} draggable={false} />
        </Link>
      ) : (
        <img className={mergedTheme.logo} src={logo.src} alt={logo.alt} draggable={false} />
      )}
    </div>
  )
}
