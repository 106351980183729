import { UserDataQuery } from '~/app/generated/graphql'

import styles from './UserMenuComponents.module.css'

export function UserMenuHeader({ user }: { user: NonNullable<UserDataQuery['user']> }) {
  return (
    <div className={styles.userContainer}>
      <p className={styles.usernameLabel}>
        {user.firstName} {user.lastName}
      </p>
      <p className={styles.mailLabel} title={user.email || ''}>
        {user.email}
      </p>
    </div>
  )
}
