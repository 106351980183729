import { useId } from 'react'

import * as collapsible from '@zag-js/collapsible'
import { normalizeProps, useMachine } from '@zag-js/react'

// temporary set any type because of error from library

export function useCollapse(collapseContext?: Omit<collapsible.Context, 'id'>) {
  const [state, send] = useMachine(
    collapsible.machine({
      ...collapseContext,
      id: useId(),
    }) as any
  )

  return collapsible.connect(state as any, send, normalizeProps)
}
