/* eslint-disable @next/next/no-img-element */
'use client'

import { useState } from 'react'

import { Form, Formik, FormikState } from 'formik'

import sprite from '@restaurantclub/svg-client-icons'

import { useNewsletterSignUpMutation } from '~/app/generated/graphql'
import Link from '~/components/Link'
import { gt } from '~/lib/gt'

import { Button } from '../Button/Button'
import Collapse from '../Collapse/CollapseView'
import { Input } from '../Input/Input'
import { useToast } from '../Toast'

import styles from './Footer.module.css'
import { FooterSection } from './FooterItems'
import { defaultNewsletter, defaultSocials, defaultToast, joinTheClubSchema, Partner, Social } from './FooterUtils'

type Props = {
  items: FooterSection[]
  theme?: typeof styles
  logoImg: string
  partners?: Array<Partner>
  socials?: Array<Social>
  copyright?: { type: 'normal' | 'right-side' | 'none'; text: string }
  newsletter?: {
    text: string
    extendText?: string
    title: string
    subtitle?: string
    emailPlaceholder: string
    button: string
    extendButtonHide: string
    extendButtonExpand: string
    bulletList?: {
      title: string
      elements: string[]
    }
  }
  toast?: { success: string; error: string }
}

export const Footer = ({
  items,
  logoImg,
  theme = styles,
  partners,
  socials = defaultSocials,
  copyright = {
    type: 'normal',
    text: gt.tp('Footer', 'Copyright © 2024 Restaurant Club Sp. z o. o., Aleje Ujazdowskie 24 lok. 23a, 00-478 Warszawa'),
  },
  newsletter = defaultNewsletter(gt),
  toast = defaultToast(gt),
}: Props) => {
  const mergedTheme = { ...styles, ...theme }
  const [signUp] = useNewsletterSignUpMutation()
  const toastState = useToast()
  const [extendText, setExtendText] = useState<boolean>(false)

  const handleSignUp = (
    email: string,
    resetForm: (nextState?: Partial<FormikState<{ email: string }>> | undefined) => void,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    signUp({
      variables: {
        email,
      },
      onCompleted: () => {
        toastState?.add({
          type: 'success',
          message: toast.success,
          containerSelector: '#FooterToastsContainer',
          size: 'regular',
        })
      },
      onError: () => {
        toastState?.add({
          type: 'error',
          message: toast.error,
          containerSelector: '#FooterToastsContainer',
          size: 'regular',
        })
      },
    })
  }

  return (
    <footer className={`container ${mergedTheme.container}`}>
      <div className={mergedTheme.logoContainer}>
        <img className={mergedTheme.logo} src={logoImg} alt={'Logo'} />
      </div>
      <section className={mergedTheme.mainContainer}>
        <div className={mergedTheme.items}>
          {items.map(column => (
            <div className={mergedTheme.item} key={column.title}>
              <h2 className={mergedTheme.itemTitle}>{column.title}</h2>
              {column.items.map(item => (
                <Link className={mergedTheme.link} href={item.href} key={item.title}>
                  {item.title}
                </Link>
              ))}
            </div>
          ))}
        </div>
        <div className={mergedTheme.itemsMobile}>
          {items.map(column => (
            <Collapse key={column.title} title={column.title} borderStyle='none' size='minimal'>
              <div className={mergedTheme.itemMobile}>
                {column.items.map(item => (
                  <Link className={mergedTheme.linkMobile} href={item.href} key={item.title}>
                    {item.title}
                  </Link>
                ))}
              </div>
            </Collapse>
          ))}
        </div>
        <div className={mergedTheme.newsLetterContainer}>
          <div id='FooterToastsContainer' />
          <div className={mergedTheme.newsLetterText}>
            <h4 className={`heading4 ${mergedTheme.newsLetterTitle}`}>{newsletter.title}</h4>
            {newsletter.subtitle && <h5 className={mergedTheme.newsLetterSubtitle}>{newsletter.subtitle}</h5>}
          </div>
          <Formik
            initialValues={{ email: '' }}
            validationSchema={joinTheClubSchema}
            validateOnMount
            onSubmit={(values, { resetForm, setSubmitting }) => {
              handleSignUp(values.email, resetForm, setSubmitting)
            }}>
            {({ values, errors, isSubmitting, handleChange }) => {
              return (
                <Form className={mergedTheme.newsLetterInputContainer}>
                  <Input
                    className={mergedTheme.newsLetterInput}
                    placeholder={newsletter.emailPlaceholder}
                    name='email'
                    value={values.email}
                    onChange={handleChange}
                  />
                  <Button
                    type='submit'
                    className={mergedTheme.newsLetterButton}
                    size='small'
                    withArrow={false}
                    disabled={!!errors.email || isSubmitting}>
                    {newsletter.button}
                  </Button>
                </Form>
              )
            }}
          </Formik>
          <div className={mergedTheme.newsLetterDescription}>
            {newsletter.text}
            {newsletter.extendText &&
              (extendText ? (
                <>
                  {' '}
                  <span dangerouslySetInnerHTML={{ __html: newsletter.extendText }} />{' '}
                  <button onClick={() => setExtendText(false)}>{newsletter.extendButtonHide}</button>
                </>
              ) : (
                <>
                  {' '}
                  <button onClick={() => setExtendText(true)}>{newsletter.extendButtonExpand}</button>
                </>
              ))}
            {newsletter.bulletList && (
              <div className={mergedTheme.bulletpointContainer}>
                <p className={mergedTheme.bulletpointTitle}>{newsletter.bulletList.title}</p>
                <ul className={mergedTheme.bulletpointList}>
                  {newsletter.bulletList.elements.map((element, index) => (
                    <li key={index} className={mergedTheme.bulletpointItem}>
                      <img width={12} height={12} className={mergedTheme.bulletpointIcon} src={`${sprite}#check`} alt='Icon check' />
                      {element}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          {copyright?.type === 'right-side' && (
            <div className={mergedTheme.newsLetterDescription} dangerouslySetInnerHTML={{ __html: copyright.text }} />
          )}
          <div id='FooterToastsContainer' />
        </div>
      </section>
      {partners && (
        <section className={mergedTheme.partnersContainer}>
          {partners?.map(partner => (
            <div className={mergedTheme.partner} key={partner.url + partner.title}>
              <div className={mergedTheme.partnerTitle}>{partner.title}</div>
              <img className={mergedTheme.partnerLogo} src={partner.url} draggable={false} />
            </div>
          ))}
        </section>
      )}
      <section className={mergedTheme.socialsContainer}>
        {copyright?.type === 'normal' && <div className={mergedTheme.copyright} dangerouslySetInnerHTML={{ __html: copyright.text }} />}
        <div className={mergedTheme.socials}>
          {socials.map(social => (
            <Link href={social.link} key={social.link}>
              <div className={mergedTheme.social}>
                <img draggable={false} src={social.img} />
              </div>
            </Link>
          ))}
        </div>
      </section>
    </footer>
  )
}
