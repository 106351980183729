import { UserDataQuery, useUserDataQuery } from '~/app/generated/graphql'

interface UseUserOutput {
  user?: UserDataQuery['user']
  loading: boolean
}

export const useUser = ({ returnBlankOnAnonymous = false } = {}): UseUserOutput => {
  const { data, loading } = useUserDataQuery({
    nextFetchPolicy: 'cache-first', // Zapewnia użycie cache przy kolejnym zapytaniu
  })
  if (returnBlankOnAnonymous && !loading && data?.user?.anonymous) {
    return {
      user: null,
      loading,
    }
  }

  return {
    user: data?.user,
    loading,
  }
}
