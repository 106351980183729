import { Country } from '~/utils/countries'

export const localeToCountry: Record<string, Country> = {
  en: Country.UnitedKingdom,
  pl: Country.Poland,
  uk: Country.Ukraine,
  cs: Country.CzechRepublic,
  de: Country.Germany,
}

export enum FlagShape {
  Square = 'square',
  Rectangle = 'rectangle',
}

export type FlagProps = {
  language: string
  shape?: FlagShape
}

export type CurrentLocation = {
  key: string
  label: string
  icon: JSX.Element
  current?: boolean
}

export interface UseLanguageSwitcherOutput {
  currentLocation: CurrentLocation
  options: CurrentLocation[]
  onLanguageChange(lang: string): void
}
