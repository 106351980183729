import { useMenuMachine } from '~/utils/hooks'

import styles from './LanguageSwitcher.module.css'
import { LanguageSwitcherButton, LanguageSwitcherList, useLanguageSwitcher } from './LanguageSwitcher.utils'

export function LanguageSwitcher() {
  const { options, currentLocation, onLanguageChange } = useLanguageSwitcher()

  const api = useMenuMachine({ closeOnSelect: true, positioning: { placement: 'top-end' } })
  const positionerProps = api.getPositionerProps()

  return (
    <>
      <LanguageSwitcherButton getTriggerProps={api.getTriggerProps} location={currentLocation} open={api.open} />

      <div {...positionerProps} style={{ ...positionerProps.style, zIndex: 1000 }} className={styles.languageSwitcherListContainer}>
        <LanguageSwitcherList options={options} onClick={onLanguageChange} getContentProps={api.getContentProps} />
      </div>
    </>
  )
}
