import { useCallback } from 'react'

import Image from 'next/image'
import { useParams } from 'next/navigation'

import sprite from '@restaurantclub/svg-client-icons/dist/sprite.svg'
import { Api } from '@zag-js/menu'
import { PropTypes } from '@zag-js/react'

import { useLanguageSwitcherContext } from '~/utils/providers'

import { gt } from '~/lib/gt'
import { loadLocales } from '~/lib/gt/loader'

import styles from './LanguageSwitcher.module.css'
import { CurrentLocation, FlagProps, FlagShape, localeToCountry, UseLanguageSwitcherOutput } from './LanguageSwitcher.types'

const Flag = ({ language, shape = FlagShape.Rectangle }: FlagProps) => {
  return <span className={`fi fi-${localeToCountry[language || 'en'].toLowerCase()}${shape === FlagShape.Square ? ' fis' : ''}`} />
}

const Chevron = ({ direction }: { direction: 'up' | 'down' | 'right' }) => (
  <Image
    src={`${sprite}#chevron-${direction}`}
    className={`icon-$chevron-${direction}`}
    alt={`chevron-${direction}`}
    width={24}
    height={24}
    style={{
      transform: direction === 'right' ? 'scale(0.5)' : '',
    }}
  />
)

export function LanguageSwitcherButton({
  open,
  getTriggerProps,
  location,
}: Readonly<{
  open: boolean
  getTriggerProps: Api<PropTypes>['getTriggerProps']
  location: CurrentLocation
}>) {
  return (
    <button {...getTriggerProps()} className={styles.languageSwitcherButton}>
      <Flag language={location.key} />
      <p>{location.label}</p>
      <Chevron direction={open ? 'down' : 'right'} />
    </button>
  )
}

export const LanguageSwitcherList = ({
  options,
  onClick,
  getContentProps,
}: {
  options: CurrentLocation[]
  onClick: (lang: string) => void
  getContentProps: Api<PropTypes>['getContentProps']
}) => {
  return (
    <ul {...getContentProps()} className={styles.languageSwitcherList}>
      {options.map(({ key, label, icon, current }) => (
        <li key={key} onClick={() => onClick(key)} className={styles.languageSwitcherListElement} aria-current={current}>
          {icon}
          {label}
        </li>
      ))}
    </ul>
  )
}

const prepareOption = (language: string) => ({
  key: language,
  label: gt.tp('Languages', language.toUpperCase()),
  icon: <Flag language={language} />,
})

export const useLanguageSwitcher = (): UseLanguageSwitcherOutput => {
  const locale = (useParams()?.lang || 'en') as string

  const { enabledLanguage } = useLanguageSwitcherContext()

  const onLanguageChange = useCallback((value: string) => {
    loadLocales(value).then(() => {
      window.location.reload()
    })
  }, [])

  const languages = Object.keys(enabledLanguage)

  const { options, currentLocation } = languages.reduce(
    (acc, cV) => {
      if (cV !== locale) acc.options.push(prepareOption(cV))
      else {
        const location = prepareOption(cV)
        acc.currentLocation = location
        acc.options.unshift({ ...location, current: true })
      }
      return acc
    },
    { options: [], currentLocation: {} } as any
  )

  return {
    options,
    currentLocation,
    onLanguageChange,
  }
}
