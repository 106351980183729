'use client'

import { createContext, useContext } from 'react'

import { locales } from '~/middleware'

type Locale = (typeof locales)[number]

type EnabledLanguage = Partial<Record<Locale, string>>

const LanguageSwitcherContext = createContext<{ enabledLanguage: EnabledLanguage }>({ enabledLanguage: {} })

export function useLanguageSwitcherContext() {
  const context = useContext(LanguageSwitcherContext)

  if (!context) {
    throw new Error('useLanguageSwitcher must be used within a LanguageSwitcherProvider')
  }

  return context
}

export const LanguageSwitcherProvider = ({
  value,
  children,
}: Readonly<{
  value: {
    enabledLanguage: EnabledLanguage
  }
  children: React.ReactNode
}>) => {
  return <LanguageSwitcherContext.Provider value={value}>{children}</LanguageSwitcherContext.Provider>
}
