import yup from '~/utils/validationMessages'

import { GT } from '~/lib/gt'

export const joinTheClubSchema = yup.object().shape({
  email: yup.string().email().required(),
})

export type Partner = {
  url: string
  title: string
}

export type Social = {
  link: string
  img: string
}

export const defaultSocials: Array<Social> = [
  {
    link: 'https://www.facebook.com/RestaurantWeekPolska',
    img: '/lp/assets/icons/facebook.svg',
  },
  {
    link: 'https://www.instagram.com/restaurantweek_pl/',
    img: '/lp/assets/icons/instagram.svg',
  },
  {
    link: 'https://www.tiktok.com/@restaurantclub_',
    img: '/lp/assets/icons/tiktok.svg',
  },
  {
    link: 'https://www.linkedin.com/company/restaurant-week-polska/mycompany/',
    img: '/lp/assets/icons/linkedin.svg',
  },
]

export const defaultNewsletter = (gt: GT) => ({
  text: gt.tp(
    'Footer',
    'By clicking “Join” you agree to receive marketing materials from us at the email address you provide. You can withdraw your consent at any time. For more information about the processing of your data, please see our Privacy Policy.'
  ),
  title: gt.tp('Footer', 'Join the Club!'),
  emailPlaceholder: gt.tp('Footer', 'Your e-mail address'),
  button: gt.tp('Footer', 'Join'),
  extendButtonHide: gt.tp('Footer', 'Hide'),
  extendButtonExpand: gt.tp('Footer', 'Expand'),
})

export const defaultToast = (gt: GT) => ({
  success: gt.tp('Footer', 'Email sent!'),
  error: gt.tp('Footer', 'Something went wrong'),
})
