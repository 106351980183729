import React from 'react'

import { useUser } from '~/utils/hooks'

import { HeaderProps } from '../../Header'

import { AuthButtons, LoginSectionMenu, MobileMenu, RightLinks } from './components'
import styles from './RightSection.module.css'

interface RightSectionProps
  extends Pick<
    HeaderProps,
    'rightLinks' | 'logo' | 'middleLinks' | 'additionalMobileMenuLinks' | 'headerTranslation' | 'buttonText' | 'redirectUrl'
  > {
  mergedTheme: { [x: string]: string }
}

export function RightSection({
  mergedTheme,
  rightLinks,
  middleLinks,
  additionalMobileMenuLinks,
  logo,
  headerTranslation,
  buttonText,
  redirectUrl,
}: RightSectionProps) {
  const { user, loading } = useUser({ returnBlankOnAnonymous: true })

  return (
    <>
      <div className={mergedTheme.right}>
        <RightLinks mergedTheme={mergedTheme} rightLinks={rightLinks} />
        {loading ? (
          <div className={styles.skeleton} />
        ) : user ? (
          <LoginSectionMenu {...{ user, rightLinks, mergedTheme, middleLinks, logo, headerTranslation }} />
        ) : (
          <AuthButtons buttonsTranslations={headerTranslation.buttons} />
        )}
      </div>
      <MobileMenu
        {...{ middleLinks, additionalMobileMenuLinks, logo, mergedTheme, user, rightLinks, headerTranslation, buttonText, redirectUrl }}
      />
    </>
  )
}
