import React from 'react'

import Image from 'next/image'

import { HeaderItems } from '~/utils/getHeaderItems'
import { useMenuMachine } from '~/utils/hooks'

import { UserDataQuery } from '~/app/generated/graphql'
import { gt } from '~/lib/gt'

import { LogoutBtn, UserMenu } from './UserMenu'

interface LoginSectionMenuProps {
  mergedTheme: { [x: string]: string }
  user: NonNullable<UserDataQuery['user']>
  headerTranslation: HeaderItems
}

export function LoginSectionMenu({ mergedTheme, user, headerTranslation }: LoginSectionMenuProps) {
  const api = useMenuMachine({ positioning: { placement: 'top-end' } })

  const positionerProps = api.getPositionerProps()

  return (
    <>
      <button {...api.getTriggerProps()} className={mergedTheme.userButton}>
        <Image
          className={mergedTheme.icon}
          src={'/lp/assets/icons/user.svg'}
          width={22}
          height={22}
          alt={gt.tp('RestaurantWeekCZ', 'User')}
          draggable={false}
        />
      </button>

      <div {...positionerProps} style={{ ...positionerProps.style, zIndex: 1000 }}>
        <div {...api.getContentProps()}>
          <UserMenu user={user} LogoutBtn={LogoutBtn} headerTranslation={headerTranslation} />
        </div>
      </div>
    </>
  )
}
