import { gt } from '~/lib/gt'

import styles from './MobileMenu.module.css'

export const ToogleButton = ({ onClick }: { onClick: () => void }) => (
  <button className={styles.hamburger} onClick={onClick}>
    <img draggable={false} src={'/lp/assets/icons/hamburger.svg'} />
  </button>
)

export const MenuHeader = ({ onClick }: { onClick: () => void }) => (
  <div>
    <button className={styles.closeButton} onClick={onClick} aria-label={gt.tp('Header', 'Close')}>
      X
    </button>
  </div>
)
