'use client'
import { useMemo } from 'react'

import { gt } from '~/lib/gt'

import Select from '../Select/SelectView'

import { useLocation } from './LocationMenuUtils'

interface LocationMenuProps {
  selectLabel?: string
}

const LocationMenu = ({ selectLabel = gt.tp('LocationMenu', 'Location') }: LocationMenuProps) => {
  const { selectedRegion, regions = [], setRegion, getRegion } = useLocation()
  const selectRegions = useMemo(
    () =>
      regions.map(region => ({
        label: region.name,
        value: region.id,
      })),
    [regions]
  )

  return (
    <div>
      <Select
        items={selectRegions}
        label={selectLabel}
        value={selectedRegion?.id}
        onChange={e => {
          const region = getRegion(e.value[0])
          if (region) {
            setRegion(region)
          }
        }}
      />
    </div>
  )
}

export default LocationMenu
