export const DEFAULT_REGION = {
  id: '5',
  name: 'Warsaw',
}
export const DEFAULT_SECTION_SLUG = 'main-page-top'

export const IMGPROXY_KEY = process.env.NEXT_PUBLIC_IMGPROXY_KEY
export const IMGPROXY_SALT = process.env.NEXT_PUBLIC_IMGPROXY_SALT
export const IMGPROXY_URL = process.env.NEXT_PUBLIC_IMGPROXY_URL

export const COOKIE = {
  LOCATION: 'location',
  NEXT_LOCALE: 'NEXT_LOCALE',
  ADULT: 'adult',
  OPTANON_CONSENT: 'OptanonConsent',
  ALERT_BOX_CLOSED: 'OptanonAlertBoxClosed',
}
